export default {
  'common.field.etd': 'ETD',
  'common.field.eta': 'ETA',
  'common.field.type': 'Type',
  'common.field.date': 'Date',
  'common.field.status': 'Status',
  'common.field.carrier.uppercase': 'CARRIER',
  'common.field.location.uppercase': 'LOCATION',
  'common.field.arrival_date.uppercase': 'ARRIVAL DATE',
  'common.field.free_time_exp.uppercase': 'FREE TIME EXP',
  'common.field.origin_destination.uppercase': 'ORIGIN/DESTINATION',
  'common.field.for_delivery_to.uppercase': 'FOR DELIVERY TO',
  'common.field.inland_freight.uppercase': 'INLAND FREIGHT',
  'common.field.original_delivery_order.uppercase': 'ORIGINAL DELIVERY ORDER',
  'common.field.prepaid_collect.uppercase': 'PREPAID / COLLECT',
  'common.field.route.uppercase': 'ROUTE',
  'common.field.hbl_no': 'HB/L No.',
  'common.field.mbl_no': 'MB/L No.',
  'common.field.document': 'Documents',
  'common.field.memo': 'Memo',
  'common.field.date_range': 'Date Range',
  'common.field.price': 'Price',
  'common.field.description': 'Description',
  'common.field.details': 'Details',
  'common.field.port_of_loading': 'POL',
  'common.field.port_of_discharge': 'POD',
  'common.field.departure': 'Departure',
  'common.field.destination': 'Destination',
  'common.field.ref': 'REF#',
  'common.field.remark': 'Remarks',
  'common.field.actions': 'Actions',
  'common.field.last_paid_date': 'Last Payment Date',
  'common.field.dc_note': 'DC Note',
  'common.field.output_by': 'Output By',
  'common.field.contact_type': 'Contact Type',
  'common.field.contact': 'Contact',
  'common.field.shipment_type': 'Shipment Type',
  'common.field.size_length': 'Length',
  'common.field.size_width': 'Width',
  'common.field.size_height': 'Height',
  'common.field.size_unit': 'Size Unit',
  'common.day_past_due_amount': 'DAYS PAST DUE',
  'common.total_balance': 'TOTAL BALANCE',
  'common.acct_name': 'ACCT NAME',
  'common.special_instructions': 'Special Instructions',
  'common.no_of_pkgs': 'No. of Pkgs',
  'common.pick_up_no': 'PICK UP NO.',
  'common.lfd': 'LFD',
  'common.pkg': 'PKG',
  'common.seal_no': 'SEAL NO.',
  'common.received_in_goods_order': 'RECEIVED IN GOODS ORDER',
  'common.per': 'PER',
  'common.preview_info': 'Preview Info',
  'common.invoice_no': 'Invoice No',
  'common.bank_name': 'Bank Name',
  'common.transaction_information': 'Transaction Information',
  'common.total_due': 'Total Due',
  'common.paid_amount': 'Paid Amount',
  'common.paid_receivable': 'Received',
  'common.paid_payable': 'Paid',
  'common.remaining_balance': 'Remaining Balance',
  'common.total_remaining_balance': 'Total remaining balance: {total}',
  'common.total_amount': 'Total Amount',
  'common.pending_amount': 'Pending Amount',
  'common.please_pay_this_amount': 'Please Pay This Amount',
  'common.tp_sz': 'TP/SZ',
  'common.container_no': 'CONTAINER NO.',
  'common.description_of_goods': 'Description of Goods',
  'common.quantity': 'Quantity',
  'common.packages': 'Packages',
  'common.description_of_charges': 'DESCRIPTION OF CHARGES',
  'common.collect': 'COLLECT',
  'common.customer_services': 'Customer Services',
  'common.date_printed': 'Date Printed',
  'common.oti_nvocc_no': 'OTI/NVOCC No.',
  'common.empty_return_depot': 'Empty return depot',
  'common.do_not_use': 'DO NOT USE',
  'common.arrival_notice_freight_invoice': 'Arrival Notice/Freight Invoice',
  'common.release_date': 'Release Date',
  'common.print_by': 'Print By',
  'common.prepared_by': 'Prepared by',
  'common.trucking_rate': 'Trucking Rate',
  'common.special_note': 'Special Note',
  'common.delivery_order': 'DELIVERY ORDER',
  'common.our_ref_no': 'OUR REF. NO.',
  'common.bl_information': 'B/L Information',
  'common.description_of_packages_and_goods': 'Description of Packages and Goods',
  'common.release_order_turnover': 'Release Order/Turnover',
  'common.release_order.notice':
    'Please retain this copy until cargo is released. If any problems occur or if you have any questions, please contact us.',
  'common.delivery_order.notice': 'Please call for an appointment before delivery',
  'common.delivery_order.notice_p1': 'As soon as discharged',
  'common.delivery_order.notice_p2': 'Before last free day expired',
  'common.field.date_range.placeholder': 'Please select a date range',
  'common.delivery_order_customer_text1':
    'THE MERCHANDISE DESCRIBED BELOW WILL BE ENTERED AND/OR FORWARDERED AS FOLLOWS',
  'common.delivery_order_customer_text2':
    'LOCAL DELIVERY OR TRANSFER BY (DELIVERY ORDER ISSUED TO)',
  'common.delivery_order_customer_text3': 'DESCRIPTION OF ARTICLES,SPECIAL MARKS &amp; EXCEPTIONS',
  'common.delivery_order_customer_text4': 'DELIVERY CLERK: DELIVER TO CARRIER SHOWN ABOVE',
  'common.field.submitted_by': 'Submitted by',
  'common.field.txn_no': 'Txn No.',
  'common.field.due_date': 'Due Date',
  'common.field.due_date.placeholder': 'Please input due date.',
  'common.field.billing_date': 'Billing Date',
  'common.field.empty': 'No entry',
  'common.pnl_report.download': 'Download PNL report in excel file format',
  'common.statement.download': 'Download statement in excel file format',
  'common.status.not_attached': 'Not Attach',
  'common.status.approved': 'Approved',
  'common.status.draft': 'Draft',
  'common.status.void': 'Void',
  'common.status.new': 'Not Submitted',
  'common.status.attached': 'Attached',
  'common.status.update': 'Update',
  'common.status.submitted': 'Submitted',
  'common.status.received': 'Received',
  'common.status.accepted': 'Accepted',
  'common.status.rejected': 'Rejected',
  'common.status.matched': 'Matched',
  'common.status.published': 'Published',
  'common.basic_setting.title': 'Basic Setting',
  'common.accounting_setting.title': 'Accounting Setting',
  'common.isf_setting.title': 'ISF Setting',

  'common.changelog.title': 'ChangeLog',
  'common.changelog.type.update': 'Updated',
  'common.changelog.type.submit': 'Submitted',
  'common.changelog.type.undo_submit': 'Unsubmitted',
  'common.changelog.type.new': 'New',
  'common.changelog.type.receive': 'Deactivated',
  'common.changelog.type.reconcile': 'Reconciled',
  'common.changelog.type.delete': 'Deleted',
  'common.changelog.type.create': 'Created',
  'common.changelog.type.block': 'Locked',
  'common.changelog.type.lock': 'Locked',
  'common.changelog.type.unblock': 'Unlocked',
  'common.changelog.type.add_memo': 'Add Memo',
  'common.changelog.type.update_memo': 'Update Memo',
  'common.changelog.type.delete_memo': 'Delete Memo',
  'common.changelog.type.void': 'Voided',
  'common.changelog.type.add_document': 'Add Document',
  'common.changelog.type.update_document': 'Update Document',
  'common.changelog.type.delete_document': 'Delete Document',
  'common.changelog.type.attach': 'Attached',
  'common.changelog.type.detach': 'Detached',
  'common.upload.document.title': 'Click or drag photo to this area to upload',
  'common.upload.document.limit.error': 'Image must smaller than 5MB!',
  'common.changelog.type.link_shipment': 'Link shipment',
  'common.changelog.type.unlink_shipment': 'Unlink Shipment',
  'common.changelog.type.back2draft': 'Back to Draft',
  'common.changelog.type.email_local_statement': 'Email Local Statement',
  'common.changelog.type.add_charge_item': 'Add Charge Item',
  'common.changelog.type.approve_charge_item': 'Approve Charge Item',
  'common.changelog.type.add_fapiao': 'Add Fapiao',
  'common.changelog.type.update_fapiao': 'Update Fapiao',
  'common.changelog.type.delete_fapiao': 'Delete Fapiao',
  'common.changelog.type.request_fapiao': 'Request For Invoicing',
  'common.changelog.type.cancel_request_fapiao': 'Cancel Invoicing Request',
  'common.changelog.type.reject': 'Rejected',
  'common.changelog.type.payment_approved': 'Payable Statement Approved',
  'common.changelog.type.receivable_approved': 'Receivable Statement Approved',
  'common.changelog.type.ready_to_pay': 'Marked Ready to Pay',
  'common.changelog.type.not_ready_to_pay': 'Marked Not Ready to Pay',
  'common.changelog.type.email': 'Emailed Document',
  'common.changelog.update.description': 'updated fields {fields}',
  'common.items.delete.confirm': 'Delete this item?',
  'common.remove_select_entries': 'Remove selected entries',
  'common.select_entries': 'Selected {count} entries',
  'common.items.only_one_line_editor.message': 'Only one item can be edited at a time',
  'common.items.only_add_one_line.message': 'Only one item can be added at a time',
  'common.record_count': '{count} Record(s)',
  'common.tools.lock': 'Lock',
  'common.tools.unlock': 'Unlock',
  'common.tools.view_pdf': 'View PDF',
  'common.request.success': 'Your request has been processed successfully.',
  'common.request.error': 'There was an error processing your request.',
  'common.update.success': 'Updated successfully',
  'common.delete.success': 'Deleted successfully',
  'common.tools.lock.success': 'Locked successfully.',
  'common.tools.unlock.success': 'Unlocked successfully.',
  'common.tools.duplicate.success': 'Duplicated successfully.',
  'common.soc.return_guarantee.title': 'AGREEMENT OF SOC EMPTY RETURN GUARANTEE',
  'common.authority_to_make_entry': 'AUTHORITY TO MAKE ENTRY',
  'common.empty.no_items': 'No Items',
  'common.view_transactions': 'View Transactions',
  'common.request_invoicing': 'Request For Invoicing',
  'common.cancel_invoicing_request': 'Cancel Invoicing Request',
  'common.make_payment': 'Make Payment',
  'common.view_bill': 'View Bill',
  'common.incl_voided': 'Incl. Voided',
  'common.receive_payment': 'Receive Payment',
  'common.generate_new_number': 'Generate New Number',
  'common.title': '{action} {model}',
  'common.title.action':
    '{action, select,' + 'create {Create}' + 'edit {Edit}' + 'new {New}' + 'other {Other}' + '}',
  'common.title.model':
    '{model, select, ' +
    'bill {Bill(A/P)}' +
    'invoice {Invoice(A/R)}' +
    'shipment {Shipment}' +
    'dc_note {D/C Note}' +
    'arrival_notice {Arrival Notice}' +
    'other {Other}' +
    'credit_note {Credit Note}' +
    'debit_note {Debit Note}' +
    '}',
  'common.color.type': 'Create {model} colors',
  'common.excel': 'Excel',
  'common.excel.download.summary': 'Download Summary',
  'common.excel.download.detail': 'Download Detail',
  'common.field.includes': 'Includes',
  'common.remaining_amount': 'Remaining Amount',
  'common.document.type.invoice_confirmation': 'Shipment Invoice',
  'common.document.type.payment_voucher': 'Accounting Voucher',
  'common.document.type.tax_report': 'Tax Report',
  'common.field.gross_profit_margin': 'Gross Profit Margin',
  'common.field.profits': 'Profits',
  'common.field.group_by': 'Group By',
  'common.field.show_on_document_as': 'Edit Info Shown On Document',
  'common.warning.no_undo': 'This action cannot be undone.',
  'common.field.created_at': 'Created At',
  'common.show_detail': 'Show detail',
  'common.before': 'Before',
  'common.after': 'After',
  'common.diff.field.key_name': 'Key name',
  'common.value': 'Value',
  'common.diff.field.status': 'Status',
  'common.selectAll': 'Select All',
  'common.import': 'Import',
  'common.carrier_website': 'Carrier Website',
  'common.status.accrued': 'Accrued',
  'common.status.actualized': 'Actualized',
  'common.error.whitesapce': 'Input should not contain leading or trailing spaces or tabs',
  'common.error.full_width_whitesapce': 'Input should not contain full-width spaces',
  'common.view.list': 'View By todo',
  'common.view.group_shipment': 'View by Shipment',
  'common.view.group_hbl': 'View by Hbls',
  'common.view.group_mbl': 'View by Mbls',
  'common.time.comparision': 'Comparision',
  'common.this_month': 'This Month',
  'common.last_month': 'Last Month',
  'common.last_two_month': 'Last Two Month',
  'common.last_30_days': 'Last 30 Days',
  'common.last_60_days': 'Last 60 Days',
  'common.previous': 'Previous',
  'common.delete': 'Are you sure you want to delete?',
  'common.discard.confirm.title': 'You have unsaved changes, proceed without saving?',
  'common.total_employee': 'Total Employee',
  'common.active_employee': 'Active Employee',
  'common.active_login_employee': 'Active Login Employee',
};
