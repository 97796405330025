import { get, round } from 'lodash';
import moment from 'moment';

export function renderLedgerLink(record: any) {
  let entityType;
  let entity;
  let text = record.ref_no;
  if (record.journal_entry) {
    entityType = 'journal_entry';
    entity = record.journal_entry;
  } else {
    for (const key of ['invoice', 'bill', 'dc_note', 'transaction', 'shipment']) {
      if (
        text &&
        record[key] &&
        record[key][key === 'shipment' ? 'shipment_number' : 'code'] === text
      ) {
        entityType = key;
        entity = record[key];
        break;
      }
      if (!text && record[key] && !record[key].code) {
        if (
          (key === 'bill' && ['expense', 'payable'].includes(record.target || '')) ||
          (key === 'transaction' && ['cash', 'transaction'].includes(record.target || ''))
        ) {
          entityType = key;
          entity = record[key];
          break;
        }
      }
    }
  }
  if (entityType && entity) {
    let path = '';
    if (entityType === 'dc_note') {
      path = `/${entity.type}_notes`;
    } else if (entityType === 'invoice') {
      path = `/invoices`;
    } else if (entityType === 'bill') {
      path = `/bills`;
    } else if (entityType === 'transaction') {
      path = `/transactions/${entity.direction === 'incoming' ? 'incoming_' : ''}payments`;
    } else if (entityType === 'journal_entry') {
      path = `/journals`;
      if (!text) {
        text = `${entity.post_date}: ${entity.date_seq}`;
      }
    } else if (entityType === 'shipment' && entity.type) {
      return {
        text: text || '-',
        href: `/shipments/${entity.type}/${entity.ext_id}`,
      };
    }
    return {
      text: text || '-',
      href: `/accounting${path}/${entity.ext_id}`,
    };
  }
  return {
    text: text || '-',
  };
}

export function lbsToKg(pounds: number) {
  return round(pounds / 2.20462, 6);
}
export function kgToLbs(kg: number) {
  return round(kg * 2.20462, 6);
}

export function cftToCbm(cubicFeet: number) {
  return round(cubicFeet / 35.3147, 6);
}

export function cbmToCft(cbm: number) {
  return round(cbm * 35.3147, 6);
}

export function getMBLFromWebsite(mbl_no: string) {
  if (!mbl_no) {
    return null;
  }
  mbl_no = mbl_no.toUpperCase();
  mbl_no = mbl_no.replace(/[^A-Z0-9]/g, '');
  const scac = mbl_no.substring(0, 4);
  const bl_no = mbl_no.substring(4);
  const mapping = {
    HLCU: `https://www.hapag-lloyd.com/en/online-business/track/track-by-booking-solution.html?blno=${mbl_no}`,
    HDMU: `https://www.hmm21.com/e-service/general/DashBoard.do`,
    CMDU: `https://www.cma-cgm.com/`,
    WHLC: `https://www.wanhai.com/views/Main.xhtml`,
    MAEU: `https://www.maersk.com/tracking/#tracking/${bl_no}`,
    COSU: `https://elines.coscoshipping.com/ebusiness/cargoTracking?trackingType=BOOKING&number=${bl_no}`,
    YMJA: `https://www.yangming.com/e-service/track_trace/track_trace_cargo_tracking.aspx`,
    ONEY: `https://ecomm.one-line.com/one-ecom/manage-shipment/cargo-tracking?trakNoParam=${bl_no}`,
    MEDU: `https://www.msc.com/`,
    EGLV: `https://ct.shipmentlink.com/servlet/TDB1_CargoTracking.do`,
    OOLU: `https://www.oocl.com/eng/Pages/default.aspx`,
  };
  return mapping?.[scac];
}

export function getLastClosingDate(monthlyClosingSetting: Record<string, any> | null | undefined) {
  const monthlyClosingDay = get(monthlyClosingSetting, 'day');
  let closingDate;
  if (monthlyClosingDay) {
    closingDate = moment().subtract(1, 'month');
    if (moment().month() !== 0) {
      closingDate = closingDate.date(monthlyClosingDay);
    } else {
      if (moment().date() <= 5) {
        closingDate = closingDate.subtract(1, 'month').date(monthlyClosingDay);
      } else {
        closingDate = closingDate.endOf('month');
      }
    }
  }
  return closingDate?.format('YYYY-MM-DD');
}

export function buildQueryParams(params: any) {
  const query = new URLSearchParams();
  for (const key in params) {
    if (params[key] !== undefined && params[key] !== null) {
      query.append(key, params[key]);
    }
  }
  return query.toString();
}

export const ExcelDefaultTheadCellStyle = {
  background: 'FF31437c',
  color: 'FFFFFFFF',
  fontSize: 10,
  fontName: 'Helvetica',
  bold: true,
  border: true,
  v: 'center',
  wrapText: true,
};

export const ExcelDefaultTbodyCellStyle = {
  wrapText: true,
  fontName: 'Helvetica',
  fontSize: 10,
};

export function slugifyCompanyName(name: string) {
  return name
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\s-]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');
}

const UNIT_MAPPING = {
  CM: 'CM',
  INCH: 'IN',
  FEET: 'FT',
};

const CONVERSION_RATES = {
  CBM_TO_CFT: 35.3147, // 1立方米 = 35.3147立方英尺

  KG_TO_LB: 2.20462, // 1公斤 = 2.20462磅
  CBM_TO_KG: 167, // 1立方米约等于167公斤 (空运计重)
} as const;

export function getCmFactorUnit(item: any, defaultUnit: string = 'CM') {
  if (item?.dimension_unit) {
    return UNIT_MAPPING[item.dimension_unit as keyof typeof UNIT_MAPPING] || defaultUnit;
  }
  return defaultUnit;
}
export function cmFactor(unit: string) {
  if (unit === 'CM') return 1;
  if (unit === 'INCH') return 16.387064;
  if (unit === 'FEET') return 28316.846592;
  throw Error(`cmFactor get an unexpected unit: ${unit}`);
}

export function convertUnit(value: number, fromUnit: string, toUnit: string): number {
  if (!value) return 0;

  const key = `${fromUnit}_TO_${toUnit}`;

  switch (key) {
    case 'CBM_TO_CFT':
      return value * CONVERSION_RATES.CBM_TO_CFT;
    case 'CFT_TO_CBM':
      return value / CONVERSION_RATES.CBM_TO_CFT;
    case 'KG_TO_LB':
      return value * CONVERSION_RATES.KG_TO_LB;
    case 'LB_TO_KG':
      return value / CONVERSION_RATES.KG_TO_LB;
    case 'CBM_TO_KG':
      return value * CONVERSION_RATES.CBM_TO_KG;
    case 'KG_TO_CBM':
      return value / CONVERSION_RATES.CBM_TO_KG;
    default:
      if (fromUnit === toUnit) return value;
      throw new Error(`Unsupported unit conversion: ${fromUnit} to ${toUnit}`);
  }
}

export function calcPCS(item: any) {
  return item.pcs;
}

export function calcKGS(item: any) {
  if (!item) return 0;
  if (item.measure_cbm) {
    return convertUnit(item.measure_cbm, 'CBM', 'KG');
  }
  return (
    (item.length * item.width * item.height * item.pcs * cmFactor(getCmFactorUnit(item))) / 6000
  );
}

export function calcLBS(item: any) {
  if (!item) return 0;
  const kgs = item.measure_cbm ? convertUnit(item.measure_cbm, 'CBM', 'KG') : calcKGS(item);
  return convertUnit(kgs, 'KG', 'LB');
}

export function calcCBM(item: any) {
  return (
    (item.length * item.width * item.height * item.pcs * cmFactor(getCmFactorUnit(item))) / 1000000
  );
}

export function calcCFT(item: any) {
  return calcCBM(item) / 0.02831685;
}

export function calcPCSSum(items: any[], precision?: number) {
  return items.reduce((sum, item) => {
    return sum + parseFloat(calcPCS(item) || 0);
  }, 0);
}

export function calcKGSSum(items: any[], precision?: number) {
  return items.reduce((sum, item) => {
    return sum + calcKGS(item) || 0;
  }, 0);
}

export function calcLBSSum(items: any[], precision?: number) {
  return items.reduce((sum, item) => {
    return sum + calcLBS(item) || 0;
  }, 0);
}

export function calcCBMSum(items: any[], precision?: number) {
  return items.reduce((sum, item) => {
    return sum + (calcCBM(item) || 0);
  }, 0);
}

export function calcCFTSum(items: any[], precision?: number) {
  return items.reduce((sum, item) => {
    return sum + (calcCFT(item) || 0);
  }, 0);
}
