import { AntdProRequest } from '@/common/antdProTyping';
import {
  Account,
  AccountAging,
  BaseChanges,
  Bill,
  ChargeItemCode,
  ChargeItemTemplate,
  Currency,
  CurrencyRate,
  DCNote,
  GLCode,
  GeneralLedger,
  HBL,
  Invoice,
  JournalEntry,
  JournalEntryChange,
  Statement,
  Transaction,
} from '@/common/type';
import { buildQueryParams } from '@/common/utils';
import request from '@/utils/request';
import { keys, map } from 'lodash';

export const getChargeItemCodes: AntdProRequest<ChargeItemCode> = (params = {}, sort?, filter?) => {
  const { pageSize, current, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      _limit: pageSize,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  };
  return request('/charge-item-codes', options);
};

export const getChargeItemCodeDetails = (ext_id: string) => {
  return request<ChargeItemCode>(`/charge-item-codes/${ext_id}`);
};

export const postChargeItemCode = (data: Record<string, any>) => {
  return request('/charge-item-codes', {
    method: 'POST',
    data,
  });
};

export const batchChargeItemCode = (data: Record<string, any>) => {
  return request('/charge-item-codes/batch', {
    method: 'POST',
    data,
  });
};

export const getTransactoinChanges = (id: string) => {
  return request(`/transactions/${id}/changes`);
};

export const updateChargeItemCode = (ext_id: string, data: Record<string, any>) => {
  return request(`/charge-item-codes/${ext_id}`, {
    method: 'PUT',
    data,
  });
};

export const deleteChargeItemCode = (ext_id: string) => {
  return request(`/charge-item-codes/${ext_id}`, {
    method: 'DELETE',
  });
};

export const getChargeItemTemplates: AntdProRequest<ChargeItemTemplate> = (
  params = {},
  sort?,
  filter?,
) => {
  const { pageSize, current, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      _limit: pageSize,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  };
  return request('/charge-item-templates', options);
};

export const postChargeItemTemplate = (data: Record<string, any>) => {
  return request('/charge-item-templates', {
    method: 'POST',
    data,
  });
};

export const updateChargeItemTemplate = (ext_id: string, data: Record<string, any>) => {
  return request(`/charge-item-templates/${ext_id}`, {
    method: 'PUT',
    data,
  });
};

export const duplicateChargeItemTemplate = (ext_id: string) => {
  return request(`/charge-item-templates/${ext_id}/duplicate`, {
    method: 'POST',
  });
};

export const deleteChargeItemTemplate = (ext_id: string) => {
  return request(`/charge-item-templates/${ext_id}`, {
    method: 'DELETE',
  });
};

export const getInvoices: AntdProRequest<Invoice> = (params = {}, sort?, filter?) => {
  const { pageSize, current, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      _limit: pageSize,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  };
  return request('/invoices', options);
};

interface InvoiceDetail extends Omit<Invoice, 'hbl'> {
  hbl?: HBL | null;
}
export const getInvoiceDetail = (ext_id: string) => {
  return request<InvoiceDetail>(`/invoices/${ext_id}`);
};

export const getInvoicesExcludeVoid: AntdProRequest<Invoice> = (params = {}, sort?, filter?) => {
  const { pageSize, current, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      ...{ status_in: ['open', 'partially_paid', 'pending_paid', 'paid'] },
      _limit: pageSize,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  };
  return request('/invoices', options);
};

export const getReceivedInvoices: AntdProRequest<Invoice> = (params = {}, sort?, filter?) => {
  const { pageSize, current, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      ...{ status: 'paid' },
      _limit: pageSize,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  };
  return request('/invoices', options);
};

export const getOpenInvoices: AntdProRequest<Invoice> = (params = {}, sort?, filter?) => {
  const { pageSize, current, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      _limit: pageSize,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  };
  return request('/invoices/open', options);
};

export const getBills: AntdProRequest<Bill> = (params = {}, sort?, filter?) => {
  const { pageSize, current, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      _limit: pageSize,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  };
  return request('/bills', options);
};

export const getPaidBills: AntdProRequest<Bill> = (params = {}, sort?, filter?) => {
  const { pageSize, current, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      ...{ status: 'paid' },
      _limit: pageSize,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  };
  return request('/bills', options);
};

export const createBill = (data: Partial<Bill>) => {
  return request<Bill>('/bills', {
    method: 'POST',
    data,
  });
};

export const deleteBill = (extId: string) => {
  return request(`/bills/${extId}`, {
    method: 'DELETE',
  });
};

export const publishBill = (extId: string, data: Record<string, any>) => {
  return request(`/bills/${extId}/publish`, {
    method: 'POST',
    data,
  });
};

export const updateBill = (ext_id: string, data: Partial<Bill>) => {
  return request<Bill>(`/bills/${ext_id}`, {
    method: 'PUT',
    data,
  });
};

export const getBillDetail = (ext_id: string) => {
  return request<Bill>(`/bills/${ext_id}`);
};

export const voidBill = (extId: string, data?: Record<string, any>) => {
  return request(`/bills/${extId}/void`, {
    method: 'POST',
    ...(data ? { data: data } : {}),
  });
};

export const getDailyOpenBills: AntdProRequest<Bill> = (params = {}, sort?, filter?) => {
  const { pageSize, current, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      _limit: pageSize,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  };
  return request('/bills/daily_open', options);
};

export const getOpenBills: AntdProRequest<Bill> = (params = {}, sort?, filter?) => {
  const { pageSize, current, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      _limit: pageSize,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  };
  return request('/bills/open', options);
};

export const applyCredits: (data: {
  invoice_ext_ids?: string[];
  bill_ext_ids?: string[];
  statement_ext_id?: string;
}) => Promise<{
  success: boolean;
}> = data => {
  return request('/transactions/apply_credits', {
    method: 'POST',
    data,
  });
};

export const createTransaction = (data: { [keys: string]: any }) => {
  const { files, ...rest } = data;
  const formData = new FormData();
  if (files && files.length) {
    files.forEach((file: File) => {
      formData.append('files.photo', file);
    });
  }
  formData.append('data', JSON.stringify(rest));
  return request('/transactions', {
    method: 'POST',
    body: formData,
  });
};

export const getTransactions: AntdProRequest<Transaction> = (params = {}, sort?, filter?) => {
  const { pageSize, current, pending, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      _limit: pageSize,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  };
  return request(`/transactions${pending ? '/pending' : ''}`, options);
};

export const getTransactionDetails = (extId: string) => {
  return request<Transaction>(`/transactions/${extId}`);
};

export const confirmTransactions = (ids: string[]) => {
  return request('/transactions/submit', {
    method: 'POST',
    data: { ids },
  });
};

export const confirmAndReconcileTransactions = (ids: string[], reconcile = false) => {
  return request('/transactions/submit', {
    method: 'POST',
    data: { ids, reconcile: true },
  });
};

export const reconcileTransaction = (id: string) => {
  const ids: string[] = [id];
  return request('/transactions/reconcile', {
    method: 'POST',
    data: { ids },
  });
};

export const unreconcileTransactions = (ids: string[], data: Record<string, any> = {}) => {
  return request('/transactions/unreconcile', {
    method: 'POST',
    data: { ...data, ...{ ids } },
  });
};

export const reconcileTransactions = (ids: string[]) => {
  return request('/transactions/reconcile', {
    method: 'POST',
    data: { ids },
  });
};

export const reconcileGeneralLedgers = (ids: number[]) => {
  return request('/general-ledgers/reconcile', {
    method: 'POST',
    data: { ids },
  });
};

export const unreconcileGeneralLedgers = (ids: number[]) => {
  return request('/general-ledgers/unreconcile', {
    method: 'POST',
    data: { ids },
  });
};

export const emailTransaction = (data: Record<string, any>) => {
  return request('/transactions/email', {
    method: 'POST',
    data,
  });
};

export const confirmAndReconcileTransaction = (id: string) => {
  const ids: string[] = [id];
  return request('/transactions/submit', {
    method: 'POST',
    data: { ids, reconcile: true },
  });
};

export const confirmTransaction = (id: string) => {
  const ids: string[] = [id];
  return request('/transactions/submit', {
    method: 'POST',
    data: { ids },
  });
};

export const updateTransaction = (extId: string, data: Record<string, any> = {}) => {
  return request(`/transactions/${extId}`, {
    method: 'PUT',
    data,
  });
};

export const voidTransaction = (extId: string, data: Record<string, any> = {}) => {
  return request(`/transactions/${extId}/void`, {
    method: 'POST',
    data,
  });
};

export const deleteTransaction = (extId: string) => {
  return request(`/transactions/${extId}`, {
    method: 'DELETE',
  });
};

export const getAccountPayablesAging: AntdProRequest<AccountAging> = (
  params = {},
  sort?,
  filter?,
) => {
  const { pageSize, current, report_type, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      _limit: pageSize,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  };
  return request(`/bills/account_aging`, options);
};

export const getAccountReceivablesAging: AntdProRequest<AccountAging> = (
  params = {},
  sort?,
  filter?,
) => {
  const { pageSize, current, report_type, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      _limit: pageSize,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  };
  return request(`/invoices/account_aging`, options);
};

export const getInvoiceAging = (contactId: number) => {
  return request('/invoices/aging', {
    params: { contact_id: contactId },
  });
};

export const getAccountBalance = (contactId: number) => {
  return request('/statements/account_total', {
    params: { contact_id: contactId },
  });
};

export const getBillAging = (contactId: number) => {
  return request('/bills/aging', {
    params: { contact_id: contactId },
  });
};

export const getStatements: AntdProRequest<Statement> = (params = {}, sort?, filter?) => {
  const { pageSize, current, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      _limit: pageSize,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  };
  return request('/statements', options);
};

export const getStatementDetails = (
  extId: string,
  group: 'invoice' | 'shipment' | 'charge_item' | undefined,
) => {
  return request<Statement>(`/statements/${extId || 'none'}${group ? `?group=${group}` : ''}`);
};

export const createStatement = (
  data: Record<string, any> & {
    contact_ext_id: string;
    start_date: string;
    end_date: string;
  },
) => {
  return request('/statements', {
    method: 'POST',
    data,
  });
};

export const updateStatement = (extId: string, data: Record<string, any>) => {
  return request(`/statements/${extId}`, {
    method: 'PUT',
    data,
  });
};

export const deleteStatement = (extId: string) => {
  return request(`/statements/${extId}`, {
    method: 'DELETE',
  });
};

export const reconcileStatement = (extId: string, data: Record<string, any> = {}) => {
  return request(`/statements/${extId}/reconcile`, {
    method: 'POST',
    data,
  });
};

export const undoReconcileStatement = (extIds: string[]) => {
  return request('/statements/undoreconcile', {
    method: 'POST',
    data: { ext_ids: extIds },
  });
};

export const createStatementDocument = (
  extId: string,
  group?: 'invoice' | 'shipment',
  overrideType?: 'debit_note' | null,
) => {
  const params = buildQueryParams({
    group,
    overrideType,
  });
  return request(`/statements/${extId}/document${params ? `?${params}` : ''}`, {
    method: 'POST',
  });
};

export const getStatementsByTransaction: AntdProRequest<Statement, { transactionExtId: string }> = (
  params: { pageSize?: number; current?: number; transactionExtId: string } = {
    transactionExtId: '',
  },
  sort?,
  filter?,
) => {
  const { pageSize, current, transactionExtId } = params;
  return request(`/transactions/${transactionExtId}/statements`, {
    params: {
      _limit: pageSize,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
    },
  });
};

export const getOpenStatements: AntdProRequest<Statement> = (params = {}, sort?, filter?) => {
  const { pageSize, current, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      _limit: pageSize,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  };
  return request('/statements/open', options);
};

export const getNewInvoiceNumber = (data: Record<string, any>) => {
  return request(`/invoices/${data.invoice_id ? `${data.invoice_id}/` : ''}invoice_number`, {
    method: 'POST',
    data,
  });
};

export const getNewBillNumber = (data: Record<string, any>) => {
  return request(`/bills/${data.bill_id ? `${data.bill_id}/` : ''}code`, {
    method: 'POST',
    data,
  });
};

export const getInvoiceTransactions = (ext_id: string) => {
  return request(`/invoices/${ext_id}/transactions`, {
    method: 'GET',
  });
};

export const getBillTransactions = (ext_id: string) => {
  return request(`/bills/${ext_id}/transactions`, {
    method: 'GET',
  });
};

export const getDCNoteTransactions = (ext_id: string) => {
  return request(`/dc-notes/${ext_id}/transactions`, {
    method: 'GET',
  });
};

export const getStatementTransactions = (ext_id: string) => {
  return request(`/statements/${ext_id}/transactions`, {
    method: 'GET',
  });
};

export const reconcileInvoices = (ext_ids: string[]) => {
  return request('/invoices/reconcile', {
    method: 'POST',
    data: { ext_ids },
  });
};

export const reconcileBills = (ext_ids: string[]) => {
  return request('/bills/reconcile', {
    method: 'POST',
    data: { ext_ids },
  });
};

export const reconcileDcNotes = (ext_ids: string[]) => {
  return request('/dc-notes/reconcile', {
    method: 'POST',
    data: { ext_ids },
  });
};

export const updateBillColor = (extId: string, color: string | null) => {
  return request(`/bills/${extId}/color`, {
    method: 'PUT',
    data: { color },
  });
};

export const sendLocalStatement = (contactExtId: string) => {
  return request(`/contacts/${contactExtId}/email_localstatement`, {
    method: 'POST',
  });
};

export const deleteInvoice = (extId: string) => {
  return request(`/invoices/${extId}`, {
    method: 'DELETE',
  });
};

export const checkAndRefreshPDF = (extId: string) => {
  return request(`/invoices/${extId}/document`, {
    method: 'GET',
  });
};

export const createPDF = (extId: string) => {
  return request(`/invoices/${extId}/document`, {
    method: 'POST',
  });
};

export const createAccountingDocumentPDF = (
  type: 'invoice' | 'bill' | 'dc_note',
  extId: string,
) => {
  return request(`/${type === 'dc_note' ? 'dc-note' : type}s/${extId}/document`, {
    method: 'POST',
  });
};

export const createDocumentPDF = (model: string, extId: string, docType: string) => {
  return request(`/${model.replace('_', '-')}s/${extId}/${docType}`, {
    method: 'POST',
  });
};

export const checkAndRefreshAccountingDocumentPDF = (
  type: 'invoice' | 'bill' | 'dc_note',
  extId: string,
) => {
  return request(`/${type === 'dc_note' ? 'dc-note' : type}s/${extId}/document`, {
    method: 'GET',
  });
};

export const voidInvoice = (extId: string, data?: Record<string, any>) => {
  return request(`/invoices/${extId}/void`, {
    method: 'POST',
    ...(data ? { data: data } : {}),
  });
};

export const getDCNotes: AntdProRequest<DCNote> = (params = {}, sort?, filter?) => {
  const { pageSize, current, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      _limit: pageSize,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort:
        sort && keys(sort).length
          ? map(
              sort,
              (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`,
            )
          : 'date:DESC',
    },
  };
  return request('/dc-notes', options);
};

interface DCNoteDetails extends Omit<DCNote, 'hbl'> {
  hbl?: HBL | null;
}
export const getDCNoteDetails = (extId: string) => {
  return request<DCNoteDetails>(`/dc-notes/${extId}`);
};
interface NewDCNote extends Omit<DCNote, 'shipment' | 'booking'> {
  shipment?: number | null;
  booking?: number | null;
}
export const createDCNote = (data: Partial<NewDCNote>) => {
  return request('/dc-notes', {
    method: 'POST',
    data,
  });
};

export const updateDCNote = (extId: string, data: Partial<DCNote>) => {
  return request(`/dc-notes/${extId}`, {
    method: 'PUT',
    data,
  });
};

export const deleteDCNote = (extId: string) => {
  return request(`/dc-notes/${extId}`, {
    method: 'DELETE',
  });
};

export const voidDCNote = (extId: string) => {
  return request(`/dc-notes/${extId}/void`, {
    method: 'POST',
  });
};

export const turnDCNoteBackToDraft = (extId: string) => {
  return request(`/dc-notes/${extId}/backtodraft`, {
    method: 'POST',
  });
};

export const getNewDCNoteNumber = (data: Record<string, any>) => {
  return request(`/dc-notes/${data.dc_note_id ? `${data.dc_note_id}/` : ''}code`, {
    method: 'POST',
    data,
  });
};

export const linkToShipment = (
  model: 'dc-note' | 'invoice' | 'bill',
  extId: string,
  data: Record<string, number>,
) => {
  return request(`/${model}s/${extId}/link_shipment`, {
    method: 'POST',
    data,
  });
};

export const unlinkFromShipment = (model: 'dc-note' | 'invoice' | 'bill', extId: string) => {
  return request(`/${model}s/${extId}/unlink_shipment`, {
    method: 'POST',
  });
};

export const getAccounts: AntdProRequest<Account> = (params = {}, sort?, filter?) => {
  const { pageSize, current, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      _limit: pageSize || -1,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  };
  return request('/accounts', options);
};

export const getAccountDetails = (extId: string) => {
  return request<Account>(`/accounts/${extId}`);
};

export const postAccount = (data: Partial<Account>) => {
  return request<Account>('/accounts', {
    method: 'POST',
    data,
  });
};

export const updateAccount = (extId: string, data: Partial<Account>) => {
  return request<Account>(`/accounts/${extId}`, {
    method: 'PUT',
    data,
  });
};

export const deleteAccount = (extId: string) => {
  return request(`/accounts/${extId}`, {
    method: 'DELETE',
  });
};

export const getJournalEntries: AntdProRequest<JournalEntry> = (params = {}, sort?, filter?) => {
  const { pageSize, current, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      _limit: pageSize || -1,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  };
  return request('/journal-entries', options);
};

export const getJournalEntryDetails = (extId: string) => {
  return request<JournalEntry>(`/journal-entries/${extId}`);
};

export const postJournalEntry = (data: Partial<JournalEntry>) => {
  return request<JournalEntry>('/journal-entries', {
    method: 'POST',
    data,
  });
};

export const updateJournalEntry = (extId: string, data: Partial<JournalEntry>) => {
  return request<JournalEntry>(`/journal-entries/${extId}`, {
    method: 'PUT',
    data,
  });
};

export const deleteJournalEntry = (extId: string) => {
  return request(`/journal-entries/${extId}`, {
    method: 'DELETE',
  });
};

export const getJournalEntryChanges = (extId: string) => {
  return request<JournalEntryChange[]>(`/journal-entries/${extId}/changes`);
};

export const blockJournalEntry = (extId: string) => {
  return request(`/journal-entries/${extId}/block`, {
    method: 'POST',
  });
};

export const unblockJournalEntry = (extId: string) => {
  return request(`/journal-entries/${extId}/unblock`, {
    method: 'POST',
  });
};

export const blockJournalEntries = (data: { ext_ids: string[] }) => {
  return request(`/journal-entries/block`, {
    method: 'POST',
    data,
  });
};

export const unblockJournalEntries = (data: { ext_ids: string[] }) => {
  return request(`/journal-entries/unblock`, {
    method: 'POST',
    data,
  });
};

export const blockTransactions = (data: { ext_ids: string[] }) => {
  return request(`/transactions/block`, {
    method: 'POST',
    data,
  });
};

export const unblockTransactions = (data: { ext_ids: string[] }) => {
  return request(`/transactions/unblock`, {
    method: 'POST',
    data,
  });
};

export const setCurrencyRateOverride = (
  type: 'invoice' | 'bill' | 'dc_note',
  extId: string,
  data: Record<string, number>,
) => {
  return request(`/${type.replace('_', '-')}s/${extId}/currency_rate_override`, {
    method: 'PUT',
    data,
  });
};

export const getARAP: AntdProRequest<(Invoice | Bill | DCNote) & {
  key: string;
  arap_type: string;
}> = (params = {}, sort?, filter?) => {
  const { pageSize, current, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      _limit: pageSize || -1,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  };
  return request('/arap', options);
};

export const blockARAP = (data: { keys?: string[]; date?: string; office?: string }) => {
  return request(`/arap/block`, {
    method: 'POST',
    data,
  });
};

export const unblockARAP = (data: { keys?: string[]; date?: string; office?: string }) => {
  return request(`/arap/unblock`, {
    method: 'POST',
    data,
  });
};

export const getBlockChangelog = (
  params: Record<string, any> = {},
  sort = { created_at: 'desc' },
) => {
  const { pageSize, current, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      _limit: pageSize || -1,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  };
  return request<BaseChanges[]>('/block-changes', options);
};

export const getGLCodes = (params = {}) => {
  return request<GLCode[]>('/gl-codes', {
    params,
  });
};

export const updateGlCode = (id: string, data: { enabled: boolean }) => {
  return request(`/gl-codes/${id}`, {
    method: 'PUT',
    data,
  });
};

export const postGlCode = (data: Record<string, any>) => {
  return request('/gl-codes', {
    method: 'POST',
    data,
  });
};

export const getCurrencies = (params = {}) => {
  return request<Currency[]>('/currencies', {
    params: {
      _limit: -1,
      _sort: 'is_default:DESC',
      ...params,
    },
  });
};

export const getCurrencyRates: AntdProRequest<CurrencyRate> = (
  params = {},
  sort = { created_at: 'descend' },
  filter?,
) => {
  const { pageSize, current, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      _limit: pageSize || -1,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  };
  return request('/currency-rates', options);
};
export const deleteCurrencyRate = (id: number) => {
  return request(`/currency-rates/${id}`, {
    method: 'DELETE',
  });
};

export const getCurrencyRatesByDate = (params = {}) => {
  return request<CurrencyRate[]>('/currencies/rates', { params });
};

export const getPaymentAmounts = (data = {}) => {
  return request('/transactions/payment_amounts', {
    method: 'POST',
    data,
  });
};

export const addExistingDocumentsToInvoice = (ext_id: string, data: { ext_ids: string[] }) => {
  return request(`/invoices/${ext_id}/documents`, {
    method: 'POST',
    data,
  });
};

export const addExistingDocumentsToBill = (ext_id: string, data: { ext_ids: string[] }) => {
  return request(`/bills/${ext_id}/documents`, {
    method: 'POST',
    data,
  });
};

export const addExistingDocumentsToDCNote = (ext_id: string, data: { ext_ids: string[] }) => {
  return request(`/dc-notes/${ext_id}/documents`, {
    method: 'POST',
    data,
  });
};

export const getGeneralLedgers: AntdProRequest<GeneralLedger> = (
  params = {},
  sort = { date: 'ascend' },
  filter?,
) => {
  const { pageSize, current, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      _limit: pageSize || -1,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      ...rest,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  };
  return request('/general-ledgers', options);
};

export const updateStatementColor = (extId: string, color: string | null) => {
  return request(`/statements/${extId}/color`, {
    method: 'PUT',
    data: { color },
  });
};

export const updateARAPColor = (type: string, extId: string, color: string | null) => {
  return request(`/arap/${type.replace('_', '-')}/${extId}/color`, {
    method: 'PUT',
    data: { color },
  });
};
export const applyFapiao = (ext_ids: string[] | null) => {
  return request(`/statements/fapiao`, {
    method: 'POST',
    data: { ext_ids },
  });
};
export const cancelApplyFapiao = (ext_ids: string[] | null) => {
  return request(`/statements/cancel_fapiao`, {
    method: 'POST',
    data: { ext_ids },
  });
};
export const getTaxCodes = (params = {}) => {
  return request('/tax-codes', {
    params: {
      _limit: -1,
      ...params,
    },
  });
};
export const updateAllTaxCodes = (data: Record<string, any>) => {
  return request('/tax-codes', {
    method: 'POST',
    data,
  });
};
export const getBankAccountBalance = (id: string | number, params: Record<string, any>) => {
  return request(`/accounts/${id}/balance`, { params });
};
export const duplicateARAP = (type: string, id: string | number, data?: Record<string, any>) => {
  return request('/arap/duplicate', {
    method: 'POST',
    data: { type, id, ...data },
  });
};
export const quickAddJournalEntry = (data: Record<string, any>) => {
  return request('/journal-entries/quick_add', {
    method: 'POST',
    data,
  });
};

export const batchImportARAP = (data: Record<string, any>) => {
  return request('/arap/batch', {
    method: 'POST',
    data,
  });
};

export const markReadyToPay = (ext_ids: string[], ready_to_pay?: boolean) => {
  return request('/bills/mark_ready', {
    method: 'POST',
    data: { ext_ids, ready_to_pay },
  });
};

export const getTaxReport = (params: Record<string, any>) => {
  return request('/tax-codes/report', {
    params,
  });
};

export const getNegativeProfitShipments = (params, sort?, filter?) => {
  const { pageSize, current, ...rest } = params;
  return request('/arap/negative_shipments', {
    params: {
      ...rest,
      _limit: pageSize || -1,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  });
};

export const performYearEndClosing = (data: Record<string, any>) => {
  return request('/journal-entries/year_end_closing', {
    method: 'POST',
    data,
  });
};

export const undoYearEndClosing = (data: Record<string, any>) => {
  return request('/journal-entries/undo_year_end_closing', {
    method: 'POST',
    data,
  });
};

export const getYearEndClosingChangelog = (params: Record<string, any>) => {
  return request('/journal-entries/year_end_closing_changes', {
    params,
  });
};

export const getFinalClosingJournal = (params: Record<string, any>) => {
  return request('/journal-entries/final_closing_journal', {
    params,
  });
};

export const deleteARAPs = (data: Record<string, any>) => {
  return request('/arap/delete', {
    method: 'POST',
    data,
  });
};

export const publishARAPs = (data: Record<'invoices' | 'bills' | 'dc_notes', number[]>) => {
  return request('/arap/publish', {
    method: 'POST',
    data,
  });
};
