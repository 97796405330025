import request from '@/utils/request';

export const getPdfTemplates = () => {
  return request(`/pdf-template-groups`, {
    params: {
      _limit: -1,
    },
  });
};
export const getPdfTemplate = (params: {
  category: string;
  dataId: string;
  source: string;
  type: string;
  locale?: string;
}) => {
  const { category, dataId, source, type, ...rest } = params;
  return request(`/pdf-template-groups/${category}/${dataId}`, {
    params: {
      from_source: source,
      type: type || 'hbl',
      ...rest,
    },
  });
};

export const getPublicPdfTemplate = (params: { id: string }) => {
  const { id } = params;
  return request(`/pdf-templates/${id}`);
};

export const getPrintableDocument = (params: { category: string; ext_id: string }) => {
  const { category, ext_id } = params;
  return request(`/pdf-template-groups/documents/${ext_id}`);
};
